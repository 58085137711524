import lightOrDarkColor from '@check-light-or-dark/color';
import lightOrDarkImage from '@check-light-or-dark/image';

window.lightOrDarkColor = lightOrDarkColor;
window.lightOrDarkImage = lightOrDarkImage;

Webflow.push(function(){

  var heroItem = $('.hero-work:not(.w-condition-invisible)');
  if(heroItem.length==0) {
    heroItem = $('.hero:not(.w-condition-invisible)');
  }

  if(heroItem.length>0) {

    var visibleHeroColor = $(heroItem).css('background-color');
    if(visibleHeroColor){
      var darkorlight = lightOrDarkColor(visibleHeroColor);
      console.log('dark or light:', darkorlight );
      if(darkorlight=='dark') {
        $('body').addClass('hero-dark');
      } else if (darkorlight=='light'){
        $('body').addClass('hero-light');
      }
    }

    let hasImg = $('.hero-work:not(.w-condition-invisible) img').length > 0;
    let bgUrl = $('.hero-work:not(.w-condition-invisible').css('background-image');
    if(hasImg) {
      if($('.hero-work:not(.w-condition-invisible) img').attr('srcset')){
        bgUrl = $('.hero-work:not(.w-condition-invisible) img').attr('srcset').split(' ')[0];
      } else {
        bgUrl = $('.hero-work:not(.w-condition-invisible) img').attr('src');
      }
    }
    if(bgUrl){
      if(!hasImg) {
        bgUrl = bgUrl.substr(5);
        bgUrl = bgUrl.substr(0, bgUrl.length-2);
      }
      
      lightOrDarkImage({
        image: bgUrl,
        x: 0,
        y: 0,
        width: 100,
        height: 200,
      }).then(darkorlight => {
        if(darkorlight=='dark') {
          $('body').addClass('hero-dark');
        } else if(darkorlight=='light') {
          $('body').addClass('hero-light');
        }
        console.log('image darkorlight:',darkorlight);
        $(window).scroll();
      });
    }

    $(window).scroll(function(){
      if($(heroItem).get(0).getBoundingClientRect().bottom > 65){
        if($('body').hasClass('hero-dark')) {
          var clr = 'white';
          if($('.project-intro').get() && $('.project-intro').get(0).getBoundingClientRect().top < 40){
            clr = 'black';
          }
          $('.breadcrumb-wrap, .breadcrumb-wrap a, .prevnext-label').css('color',clr);
        } else if($('body').hasClass('hero-light')) {
          $('.breadcrumb-wrap, .breadcrumb-wrap a, .prevnext-label').css('color','black');
        }
      } else {
        $('.breadcrumb-wrap, .breadcrumb-wrap a, .prevnext-label').removeAttr('style');
      }
    });

    $(window).trigger('scroll');

  }

});
